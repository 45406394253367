@charset 'utf-8';
@use 'var.scss' as *;
@use 'mixins.scss' as *;

html,body {
    font-family: Century Gothic, Verdana, sans-serif;
}
body {
    background: url(../images/common/bg_body.png) repeat-x top;
    background-size: 1px 900px;
}
h2 {
    color: #555;
    font-size: 32px;
    font-weight: normal;
    margin: 0 0 1em;
    padding: 0;
    @include mq () {
        font-size: 24px;
    }
}
p {
    color: #555;
    font-size: 14px;
    line-height: 1.5em;
}
.container {
    background: #fff;
    border-radius: 15px;
    display: block;
    font-size: 14px;
    margin: 120px auto;
    padding: 1em 1em 2em;
    position: relative;
    text-align: center;
    width: 600px;
    @include mq () {
        box-sizing: border-box;
        padding: calc(#{$offset-mobile} * 2);
        width: 100%;
    }
}
.logo-container {
    margin: 0 auto;
    overflow: hidden;
    width: 190px;
    @include mq () {
        width: 50%;
    }
}
.logo-container img {
    margin: 0 0 10px;
    width: 320px;
    @include mq () {
        width: 100%;
    }
}