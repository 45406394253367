html, body {
  color: #383838;
  font-family: Arial, sans-serif;
  font-size: 85%;
}

html {
  min-height: 100vh;
  overflow: auto;
  position: relative;
}

h1, h2, h3, h4, h5, p, dl, ul, figure {
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 1.1rem;
}

header, footer, #contents {
  margin: 0 auto;
  width: 1110px;
}

#contents {
  -js-display: flex;
  display: -webkit-box; /* lte android 4.3 */
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  -ms-flex-pack: justify; /* IE10 */
  justify-content: space-between;
  margin: 0 auto 10px;
}
#contents::after {
  clear: both;
  content: " ";
  display: block;
  height: 0;
  visibility: hidden;
}
#contents noscript, #contents .no-cookie, #contents .wait {
  background: #fff;
  border: 2px solid #f33;
  border-radius: 10px;
  box-shadow: 2px 2px 2px #999;
  color: #f33;
  font-size: 1.1rem;
  left: 0;
  margin: auto;
  padding: 2em 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 200px;
  width: 90%;
  z-index: 2;
}
#contents noscript::after, #contents .no-cookie::after, #contents .wait::after {
  background: #fff;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.3;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}

html, body {
  font-family: Century Gothic, Verdana, sans-serif;
}

body {
  background: url(../images/common/bg_body.png) repeat-x top;
  background-size: 1px 900px;
}

h2 {
  color: #555;
  font-size: 32px;
  font-weight: normal;
  margin: 0 0 1em;
  padding: 0;
}
@media all and (max-width: 600px) {
  h2 {
    font-size: 24px;
  }
}

p {
  color: #555;
  font-size: 14px;
  line-height: 1.5em;
}

.container {
  background: #fff;
  border-radius: 15px;
  display: block;
  font-size: 14px;
  margin: 120px auto;
  padding: 1em 1em 2em;
  position: relative;
  text-align: center;
  width: 600px;
}
@media all and (max-width: 600px) {
  .container {
    box-sizing: border-box;
    padding: calc(2vw * 2);
    width: 100%;
  }
}

.logo-container {
  margin: 0 auto;
  overflow: hidden;
  width: 190px;
}
@media all and (max-width: 600px) {
  .logo-container {
    width: 50%;
  }
}

.logo-container img {
  margin: 0 0 10px;
  width: 320px;
}
@media all and (max-width: 600px) {
  .logo-container img {
    width: 100%;
  }
}