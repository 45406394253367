$contents-width: 1110px;
$menu-width: 250px;
$main-width: 580px;
$cart-width: 250px;
$basic-offset: 15px;

$color-text-basic: #383838;
$color-basic: #82a3c9;
$color-basic-active: #165fb2;
$color-basic-darker: #0a4d7a;
$color-menu-background: #86C7DF;
$color-cart-background: #509DC5;
$color-table-background: #38679B;
$color-gray: #c8c8c8;
$color-light-gray: #f8f8f8;
$color-dark-gray: #787878;
$color-orange: #E69C3D;
$color-noticeable: #A53719;
$color-inactive: #ccc;
$color-red: #ee0000;
$color-black: #080808;

$offset-mobile: 2vw;
$duration: .2s;
$transition-duration: .5s;

$breakpoints: (
  'small': 'all and (max-width: 600px)',
);

html,body {
  color: $color-text-basic;
  font-family: Arial, sans-serif;
  font-size: 85%;
}
html {
    min-height: 100vh;
  overflow: auto;
  position: relative;
}
h1,h2,h3,h4,h5,p,dl,ul,figure {
  margin: 0;
  padding: 0;
}
h3 {
  font-size: 1.1rem;
}
header, footer, #contents {
  margin: 0 auto;
  width: $contents-width;
}
#contents {
    -js-display: flex;
  display: -webkit-box; /* lte android 4.3 */
  display: -ms-flexbox; /* IE10 */
  display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
    -ms-flex-pack: justify; /* IE10 */
  justify-content: space-between;
  margin: 0 auto 10px;
  &::after {
    clear: both;
    content: ' ';
    display: block;
    height: 0;
    visibility: hidden;
  }
  noscript, .no-cookie, .wait {
    background: #fff;
    border: 2px solid #f33;
    border-radius: 10px;
    box-shadow: 2px 2px 2px #999;
    color: #f33;
    font-size: 1.1rem;
    left: 0;
    margin: auto;
    padding: 2em 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 200px;
    width: 90%;
    z-index: 2;
    &::after {
      background: #fff;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0.3;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }
} // #contents
