@charset 'utf-8';
@use 'var.scss' as *;
@use "sass:map";

@mixin mq($breakpoint: small) {
  @media #{map.get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin css3($property, $value) {
  @each $prefix in -webkit-, -moz-, -ms-, -o-, '' {
    #{$prefix}#{$property}: $value;
  }
}
